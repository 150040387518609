import React, { useRef } from "react"

const Section = ({ id, className, classNameMain, children }) => {
  const ref = useRef()

  return (
    <>
      <section
        id={id}
        className={`m-0 p-0 lg:py-32 md:py-24 py-16 ${classNameMain}`}
      >
        <div ref={ref} className={`m-0 p-0 lg:px-8 md:px-6 px-4 ${className}`}>
          {children}
        </div>
      </section>
    </>
  )
}

export default Section
