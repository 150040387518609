import { useAuthenticator } from "@aws-amplify/ui-react"
import React from "react"
import { onOpenPage } from "../../utils/utils"
import { LoaderTheme } from "../loader"

const MenuButtons = ({
  user,
  onClickFn = () => {},
  linkSizeClass = "lg:text-sm md:text-base sm:text-base text-base",
}) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus])

  const onOpenDashboard = () => {
    onOpenPage("dashboard")
  }

  return (
    <>
      {user ? (
        <>
          <li className="w-fit m-0 p-0 lg:mx-4 md:mx-3 sm:mx-2 mx-2 lg:mr-0 lg:flex hidden justify-center items-center">
            <button
              id="header-user-profile"
              aria-label="Customer Dashboard"
              className={`menu-button w-fit m-0 p-0 flex flex-row justify-end items-center font-bold ${linkSizeClass} border-solid border-2 border-transparent`}
              onClick={onOpenDashboard}
            >
              <div className="w-fit flex flex-col justify-start items-center text-primary-default hover:text-primary-dark">
                {user && user.email}
              </div>

              <div className="w-10 h-10 ml-2 pt-0.5 flex flex-col justify-center items-center text-white rounded-full bg-primary-dark">
                {user && Array.from(user.email)[0].toUpperCase()}
              </div>
            </button>
          </li>
        </>
      ) : authStatus !== "configuring" ? (
        <>
          <li className="lg:w-fit w-full m-0 p-0 lg:mx-4 md:mx-3 sm:mx-2 mx-2 flex justify-center items-center">
            <a
              href="/login"
              className={`menu-button lg:w-28 md:w-3/5 w-full py-2.5 flex justify-center font-bold text-primary-default hover:text-white hover:bg-primary-dark ${linkSizeClass} rounded-full border-solid border-2 border-primary-default hover:border-primary-dark`}
              onClick={onClickFn}
            >
              Log in
            </a>
          </li>

          <li className="lg:w-fit w-full m-0 p-0 flex justify-center items-center">
            <a
              href="/signup"
              className={`menu-button lg:w-28 md:w-3/5 w-full py-2.5 flex justify-center font-bold text-white bg-primary-default hover:bg-primary-dark ${linkSizeClass} rounded-full border-solid border-2 border-primary-default hover:border-primary-dark`}
              onClick={onClickFn}
            >
              Get started
            </a>
          </li>
        </>
      ) : (
        <li className="lg:w-fit w-full m-0 p-0 lg:ml-4 md:ml-3 sm:ml-2 ml-2 flex justify-center items-center">
          <LoaderTheme size="large" />
        </li>
      )}
    </>
  )
}

export default MenuButtons
