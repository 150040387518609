import * as models from "../models" // eslint-disable-line no-unused-vars
import { DataStore } from "@aws-amplify/datastore"
import {
  Authenticator,
  CheckboxField,
  Heading,
  Radio,
  RadioGroupField,
  View,
  useAuthenticator,
} from "@aws-amplify/ui-react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import { onOpenPage /*, onSignOut */ } from "../utils/utils"
import Section from "./section"

const CustomAuthenticator = ({ initialState, children }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus])

  const year = new Date().getFullYear()

  const handleAuthenticated = async page => onOpenPage(page)

  useEffect(() => {
    // console.log(authStatus, initialState)

    const onCheckUser = async () => {
      try {
        if (authStatus === "authenticated") {
          // console.log(initialState, "checking user")

          switch (initialState) {
            case "signUp":
            case "confirmSignUp":
              handleAuthenticated("settings")
              break

            case "signIn":
            case "forgotPassword":
            case "confirmResetPassword":
            case "forceNewPassword":
              handleAuthenticated("dashboard")
              break

            default:
              break
          }
        } else {
          if (authStatus === "unauthenticated" && initialState !== "signIn") {
            await DataStore.clear()
            await DataStore.stop()
            // onSignOut()
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    onCheckUser()
  }, [authStatus, initialState])

  const components = {
    Header() {
      return (
        <View className="h-header-default flex justify-center items-center">
          <Link id="header-logo" to="/">
            <StaticImage
              src="../images/img-logo.png"
              loading="lazy"
              height={50}
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Bitcoin.bb"
              imgStyle={{ objectFit: "contain" }}
              placeholder="none"
            />
          </Link>
        </View>
      )
    },
    Footer() {
      return (
        <View className="h-fit lg:my-8 md:my-6 my-4 flex justify-center items-center">
          <div className="text-xs text-tertiary-default">
            &copy; {year} Bitcoin Inc.
            <a
              href="/policies/terms"
              target="_blank"
              className="px-4 text-tertiary-default hover:text-primary-default hover:underline"
            >
              Terms
            </a>
            <a
              href="/policies/privacy"
              target="_blank"
              className="text-tertiary-default hover:text-primary-default hover:underline"
            >
              Privacy
            </a>
          </div>
        </View>
      )
    },
    SignUp: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Create Account
              </Heading>

              <h3 className="w-full text-center text-base text-color-subheading">
                Create your new Bitcoin.bb account.
              </h3>
            </View>
          </Section>
        )
      },
      FormFields() {
        const { validationErrors } = useAuthenticator()

        return (
          <>
            {/* re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* append & require customer account type field to sign up form */}
            <div id="custom-account" className="w-full flex flex-col">
              <RadioGroupField
                name="custom:account"
                legend="Select account type:"
                defaultValue="individual"
                className="hidden lg:mb-8 md:mb-6 mb-4 lg:pt-4 md:pt-3 pt-2 flex flex-row justify-center items-center"
              >
                <Radio
                  value="individual"
                  className="lg:mx-4 md:mx-3 mx-1.5 text-secondary-default cursor-pointer"
                >
                  Individual
                </Radio>

                {/* <Radio
                  value="company"
                  className="text-secondary-default cursor-pointer"
                >
                  Company
                </Radio> */}
              </RadioGroupField>
            </div>

            {/* append and require terms & conditions field to sign up form */}
            <div
              id="custom-toc"
              className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col justify-start"
            >
              <CheckboxField
                name="custom:toc"
                label="By creating an account, I have read and agree to the Bitcoin.bb Terms & Conditions."
                className="flex items-start"
                value="yes"
                errorMessage={validationErrors["custom:toc"]}
                hasError={!!validationErrors["custom:toc"]}
              />
            </div>
          </>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Already have a Bitcoin.bb account?{" "}
                <span className="font-bold">Log in</span>
              </a>
            </View>
          </Section>
        )
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Account Activation
              </Heading>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/signup"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                New to Bitcoin.bb? <span className="font-bold">Sign up</span>
              </a>
            </View>
          </Section>
        )
      },
    },
    SignIn: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Log In
              </Heading>

              <h3 className="w-full text-center text-base text-color-subheading">
                Good to see you again.
              </h3>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/signup"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                New to Bitcoin.bb? <span className="font-bold">Sign up</span>
              </a>
            </View>

            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/reset-password"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Forgot your password?{" "}
                <span className="font-bold">Reset password</span>
              </a>
            </View>
          </Section>
        )
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Enter Security Code
              </Heading>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Back to Log in
              </a>
            </View>
          </Section>
        )
      },
    },
    ForgotPassword: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-4 md:mb-3 mb-2 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Reset Password
              </Heading>

              <h3 className="w-full text-center text-base text-color-subheading">
                We will send a recovery code to your email.
              </h3>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Already have an account?{" "}
                <span className="font-bold">Log in</span>
              </a>
            </View>
          </Section>
        )
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Reset Password
              </Heading>

              <h3 className="w-full text-center text-base text-color-subheading">
                Change your password.
              </h3>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Already have an account?{" "}
                <span className="font-bold">Log in</span>
              </a>
            </View>
          </Section>
        )
      },
    },
    ForceNewPassword: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-4 md:mb-3 mb-2 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Change Password
              </Heading>

              <h3 className="w-full text-center text-base text-color-subheading">
                Update your password.
              </h3>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Back to Log in
              </a>
            </View>
          </Section>
        )
      },
    },
    SetupTOTP: {
      Header() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mb-8 md:mb-6 mb-4 flex flex-col flex-wrap items-center">
              <Heading
                className="w-full lg:mb-4 md:mb-3 mb-2 text-center lg:text-40 md:text-4xl text-2xl font-bold text-color-headline"
                level={3}
              >
                Enter Information
              </Heading>
            </View>
          </Section>
        )
      },
      Footer() {
        return (
          <Section
            classNameMain="lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0"
            className="max-w-theme-max-w lg:pl-0 md:pl-0 pl-0 lg:pr-0 md:pr-0 pr-0 flex flex-col"
          >
            <View className="w-full lg:mt-16 md:mt-12 mt-8 lg:mb-8 md:mb-6 mb-4 flex flex-row text-sm">
              <a
                href="/login"
                className="text-tertiary-default hover:text-primary-default hover:underline"
              >
                Back to Log in
              </a>
            </View>
          </Section>
        )
      },
    },
  }

  const formFields = {
    signUp: {
      email: {
        label: "",
        placeholder: "Enter your email",
        isRequired: true,
        order: 1,
      },
      password: {
        label: "",
        placeholder: "Enter your password",
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: "",
        placeholder: "Confirm your password",
        isRequired: true,
        order: 3,
      },
    },
    confirmSignUp: {
      confirmation_code: {
        label: "",
        placeholder: "Enter your confirmation code",
        isRequired: true,
      },
    },
    signIn: {
      username: {
        label: "",
        placeholder: "Enter your email",
        isRequired: true,
      },
      password: {
        label: "",
        placeholder: "Enter your password",
        isRequired: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "",
        placeholder: "Enter your security code",
        isRequired: true,
      },
    },
    forgotPassword: {
      username: {
        label: "",
        placeholder: "Enter your email",
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: "",
        placeholder: "Enter your verification code",
        isRequired: false,
      },
      password: {
        label: "",
        placeholder: "Enter your password",
      },
      confirm_password: {
        label: "",
        placeholder: "Enter your password again",
      },
    },
    forceNewPassword: {
      password: {
        label: "",
        placeholder: "Enter your new password",
      },
      confirm_password: {
        label: "",
        placeholder: "Enter your password again",
      },
    },
  }

  const services = {
    async validateCustomSignUp(formData) {
      if (!formData["custom:toc"]) {
        return {
          "custom:toc": `You must agree to the Bitcoin.bb Terms & Conditions`,
        }
      }
    },
  }

  return (
    <div id="bitcoinAuth">
      <Authenticator
        usernameAlias="email"
        initialState={initialState}
        formFields={formFields}
        components={components}
        services={services}
      >
        {children}
      </Authenticator>
    </div>
  )
}

export default CustomAuthenticator
