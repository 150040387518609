// @ts-check
import { initSchema } from "@aws-amplify/datastore"
import { schema } from "./schema"

const TransactionType = {
  TRANSFER: "TRANSFER",
  DEPOSIT: "DEPOSIT",
  WITHDRAWAL: "WITHDRAWAL",
}

const TransactionState = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
}

const WalletType = {
  HOT: "HOT",
  COLD: "COLD",
  CUSTODY: "CUSTODY",
}

const TransferType = {
  SEND: "SEND",
  RECEIVE: "RECEIVE",
  INTERNAL: "INTERNAL",
}

const AccountType = {
  INDIVIDUAL: "individual",
  COMPANY: "company",
}

const {
  IndividualData,
  ContactIAData,
  EmploymentData,
  CompanyData,
  ContactCAData,
  BusinessData,
  FundData,
  VIPFundData,
  UserLevel,
  ActivityLog,
  AppointmentData,
  RequestData,
  ContactForm,
  BitcoinAddress,
  Transaction,
} = initSchema(schema)

export {
  IndividualData,
  ContactIAData,
  EmploymentData,
  CompanyData,
  ContactCAData,
  BusinessData,
  FundData,
  VIPFundData,
  UserLevel,
  ActivityLog,
  AppointmentData,
  RequestData,
  ContactForm,
  BitcoinAddress,
  Transaction,
  TransactionType,
  TransactionState,
  WalletType,
  TransferType,
  AccountType,
}
