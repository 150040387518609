import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export default function FooterImmersive(props) {
  const { siteTitle } = props

  return (
    <footer
      id="footer-immersive"
      className="w-full flex justify-center bg-footer-default"
    >
      <div className="max-w-theme-max-w w-full lg:p-8 md:p-6 p-4 flex lg:flex-row md:flex-row flex-col lg:justify-between md:justify-between justify-center">
        <Link to="/" className="lg:mb-0 md:mb-0 mb-8 flex self-center">
          <StaticImage
            src="../images/img-logo-dark.png"
            loading="eager"
            height={52}
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt={siteTitle}
            placeholder="none"
          />
        </Link>

        <div className="flex lg:justify-end md:justify-end justify-center items-center text-sm">
          <span className="lg:mb-0 md:mb-0 mb-4 text-center text-white">
            &copy; {new Date().getFullYear()} Bitcoin Inc. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}
