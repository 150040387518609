/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://akxsxn16r3.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "addressApi",
            "endpoint": "https://h5gfodug0g.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "requestApi",
            "endpoint": "https://o4fykan35k.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "transactionApi",
            "endpoint": "https://jnu575l863.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6r6x77jmvjdxplhouatninifl4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fo2eh4vckff5zcj7nxhxyubm3u",
    "aws_cognito_identity_pool_id": "us-east-2:2d1a4b06-fc59-4663-8f9c-d4485cb9bc95",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_EDC5ocCPD",
    "aws_user_pools_web_client_id": "414nm4lfj8024f8hcp47fqm1a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bitcoin-storage-fbac3250bba9c-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
