import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signOut,
  updateUserAttributes,
} from "aws-amplify/auth"
import { Hub } from "aws-amplify/utils"
import { navigate } from "gatsby"
import { TYPE_INDIVIDUAL } from "./constants"

const onCapitalisedCase = string => {
  // Function capitalises the first letter of each word in the string.
  if (!string || string === null || string.trim() === "") return ""
  let stringArray = string.split(" ")
  let result = ""
  stringArray.map(
    word =>
      (result +=
        result === ""
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : " " + word.charAt(0).toUpperCase() + word.slice(1)),
  )
  stringArray = result.split("-")
  result = ""
  stringArray.map(
    word =>
      (result +=
        result === ""
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : "-" + word.charAt(0).toUpperCase() + word.slice(1)),
  )
  return result
}

const onCheckDeclaration = async (setDeclaration, setLoading) => {
  try {
    setLoading(true)
    const attributes = await fetchUserAttributes()
    if (!attributes["custom:declaration"]) {
      updateUserAttributes({
        userAttributes: {
          "custom:declaration": "no",
        },
      })
      setDeclaration("no")
    } else setDeclaration(attributes["custom:declaration"])
  } catch (e) {
    console.log(e)
  } finally {
    setLoading(false)
  }
}

const onCheckUser = async (setUser, setLoading) => {
  // Function checks if the logged-in user is valid.
  try {
    setLoading(true)
    const currentUser = await getCurrentUser()
    if (currentUser) {
      const userData = await fetchUserAttributes()

      if (!userData["custom:identity"]) {
        const credentials = await fetchAuthSession({ forceRefresh: false })

        updateUserAttributes({
          userAttributes: {
            "custom:identity": credentials.identityId,
          },
        })
        if (
          Math.floor(Date.now() / 1000) >
          credentials.tokens?.accessToken?.payload.auth_time + 3600
        ) {
          onSignOut()
        }
      }
      setUser(userData)
    }
  } catch (e) {
    // console.log(e)
    await signOut()
    onOpenPage("/login")
  } finally {
    setLoading(false)
  }
}

const onCheckUserDefault = async (setUser, setLoading) => {
  // Function checks if the logged-in user is valid.
  try {
    setLoading(true)
    const currentUser = await getCurrentUser()
    if (currentUser) {
      const userData = await fetchUserAttributes()
      setUser(userData)
    }
  } catch (e) {
    // console.log(e)
  } finally {
    setLoading(false)
  }
}

const onCheckUserExternal = async setUser => {
  // Function checks if the logged-in user is valid.
  try {
    const currentUser = await getCurrentUser()
    if (currentUser) {
      const userData = await fetchUserAttributes()
      setUser(userData)
    }
  } catch (e) {
    // console.log(e)
  }
}

const onDataStoreSync = (setIsSynced, setLoading, setIsSyncing) => {
  // Function ensures datastore is in sync.
  try {
    setLoading(true)
    const handleEvents = capsule => {
      const { event } = capsule.payload
      // console.log(event)

      if (event === "syncQueriesStarted") {
        setIsSyncing(true)
        console.log("sync: started")
      }

      if (event === "syncQueriesReady") {
        setIsSynced(true)
        setIsSyncing(false)
        console.log("sync: successful")
      }
    }
    const removeHubListener = Hub.listen("datastore", handleEvents)
    return () => removeHubListener()
  } catch (e) {
    console.log(e)
  } finally {
    setLoading(false)
  }
}

const onDayNth = day => {
  if (day > 3 && day < 21) return "th"
  switch (day % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

const onFormatMoney = value => {
  return parseFloat(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const onGetTier = level => {
  // Function determines customer tier.
  let tier = "0"
  if (level !== undefined) {
    switch (level) {
      case "-1":
        tier = "-1"
        break
      case "5":
        tier = "4"
        break
      case "4":
        tier = "3"
        break
      case "3":
        tier = "2"
        break
      case "2":
      case "1":
        tier = "1"
        break
      default:
        tier = "0"
        break
    }
  }
  return tier
}

const onGreeting = (type, recordOne) => {
  // Function builds the account dashboard's greeting message.
  const hour = new Date().getHours()
  const greeting = `Good ${
    (hour < 12 && "morning") ||
    (hour < 17 && "afternoon") ||
    (hour < 21 && "evening") ||
    "night"
  }`
  let name = ""
  if (recordOne !== undefined) {
    name = onCapitalisedCase(
      type === TYPE_INDIVIDUAL ? recordOne.firstname : recordOne.companyname,
    )
  }
  return greeting + (name && "$" + name)
}

const onOpenPage = title => {
  switch (title) {
    case "login":
      navigate("/login")
      break

    case "dashboard":
      navigate("/user/dashboard")
      break

    case "requests":
      navigate("/user/requests")
      break

    case "transactions":
      navigate("/user/transactions")
      break

    case "appointments":
      navigate("/user/appointments")
      break

    case "settings":
      navigate("/user/settings")
      break

    case "settings-security-mfa":
      navigate("/user/settings#page-security-mfa")
      break

    case "cookies-policy":
      navigate("/policies/cookies")
      break

    case "terms-and-conditions":
      navigate("/policies/terms")
      break

    case "privacy-policy":
      navigate("/policies/privacy")
      break

    default:
      navigate("/")
      break
  }
}

const onScrollTop = () => {
  // Function scrolls to top on page
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
}

const onUpdateDeclaration = async (setDeclaration, setLoading) => {
  try {
    // setLoading(true)
    updateUserAttributes({
      userAttributes: {
        "custom:declaration": "yes",
      },
    })
    setDeclaration("yes")
  } catch (e) {
    console.log(e)
  } finally {
    // setLoading(false)
  }
}

const onSignOut = async () => {
  try {
    await signOut()
    onOpenPage("/login")
  } catch (e) {
    console.log(e)
  }
}

export {
  onCapitalisedCase,
  onCheckDeclaration,
  onCheckUser,
  onCheckUserDefault,
  onCheckUserExternal,
  onDataStoreSync,
  onDayNth,
  onFormatMoney,
  onGetTier,
  onGreeting,
  onOpenPage,
  onScrollTop,
  onSignOut,
  onUpdateDeclaration,
}
