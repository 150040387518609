const ACCOUNT_STATUS = [
  { value: "0", label: "Account Pending" },
  { value: "1", label: "Account Open" },
  { value: "2", label: "Account Active" },
  { value: "3", label: "Account Closed" },
  { value: "4", label: "Account Unknown" },
]

const APPOINTMENT_STATUS = [
  { value: "0", label: "pending" },
  { value: "1", label: "cancelled" },
  { value: "2", label: "expired" },
  { value: "3", label: "complete" },
]

const APP_NAME = "Bitcoin.bb"

const BUSINESS_DOCUMENT_OPTIONS = [
  "Certificate and Articles of Incorporation or Continuance",
  "Certificate of Registration",
  "By-Laws",
]

const BUSINESS_OPTIONS = [
  "Company",
  "Sole Proprietorship",
  "Partnership",
  "Other",
]

const BUSINESS_TYPE = ["Sole Trader", "Partnership"]

const BUY_LIMITS = [
  { value: "0", label: 0 },
  { value: "1", label: 1000 },
  { value: "2", label: 10000 },
  { value: "3", label: 50000 },
]

const BUY_LIMITS_COMPANY = [
  { value: "0", label: 0 },
  { value: "1", label: 10000 },
  { value: "2", label: 10000 },
  { value: "3", label: 50000 },
]

const CONTACT_CA_OPTIONS = ["Utility Bill", "Bank Statement", "Other"]

const CONTACT_IA_OPTIONS = ["Utility Bill", "Bank Statement", "Other"]

const CURRENCY_OPTIONS = [
  { value: "0", label: "BBD" },
  { value: "1", label: "USD" },
  { value: "2", label: "BTC" },
]

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const DOCUMENTS_POA = ["Utility Bill", "Bank Statement"]

const DOCUMENTS_POI = ["National ID Card", "Driving License", "Passport"]

const DOCUMENTS_POO = [
  "Statement of UBOs/shareholders on the company letterhead",
  "Shareholders Letter on the company letterhead",
  "Share Certificates",
  "Online system showing the names of UBOs/shareholders",
]

const DOCUMENTS_POP = ["Partnership Agreement on the business letterhead"]

const ENTITY_TYPE = ["Incorporated Company", "Registered Business"]

const FORM_STATUS = [
  { value: "0", label: "pending" },
  { value: "1", label: "rejected" },
  { value: "2", label: "approved" },
]

const FORM_TYPE = [
  { value: "0", label: "Individual" },
  { value: "1", label: "Company" },
  { value: "2", label: "Contact" },
  { value: "3", label: "Employment" },
  { value: "4", label: "Business" },
  { value: "5", label: "Source Of Funds" },
  { value: "6", label: "VIP Fund" },
]

const LIMIT_DEFAULT = 10

const MONTHS = [
  "Jan.",
  "Feb.",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dec.",
]

const REQUEST_STATUS = [
  { value: "0", label: "requested" },
  { value: "1", label: "revoked" },
  { value: "2", label: "rejected" },
  { value: "3", label: "expired" },
  { value: "4", label: "approved" },
  { value: "5", label: "payment complete" },
  { value: "6", label: "transfer complete" },
  { value: "7", label: "complete" },
]

const SELL_LIMITS = [
  { value: "0", label: 0 },
  { value: "1", label: 10000 },
  { value: "2", label: 10000 },
  { value: "3", label: 50000 },
]

const SELL_LIMITS_COMPANY = [
  { value: "0", label: 0 },
  { value: "1", label: 10000 },
  { value: "2", label: 10000 },
  { value: "3", label: 50000 },
]

const SOF_OPTIONS = ["Salary", "Savings", "Pension", "Other"]

const TRANSACTION_STATUS = [{ value: "0", label: "complete" }]

const TRANSACTION_TYPE = [
  { value: "0", label: "buy" },
  { value: "1", label: "sell" },
]

const TYPE_COMPANY = "company"

const TYPE_INDIVIDUAL = "individual"

const VIP_OPTIONS_COMPANY = [
  "Select..",
  "$1,000,000",
  "$5,000,000",
  "$20,000,000",
  "$100,000,000",
]

const VIP_OPTIONS_INDIVIDUAL = [
  "Select..",
  "$100,000",
  "$500,000",
  "$2,000,000",
  "$10,000,000",
]

const XR_BUY = 2

const XR_SELL = 2.03

export {
  ACCOUNT_STATUS,
  APPOINTMENT_STATUS,
  APP_NAME,
  BUSINESS_DOCUMENT_OPTIONS,
  BUSINESS_OPTIONS,
  BUSINESS_TYPE,
  BUY_LIMITS,
  BUY_LIMITS_COMPANY,
  CONTACT_CA_OPTIONS,
  CONTACT_IA_OPTIONS,
  CURRENCY_OPTIONS,
  DAYS,
  DOCUMENTS_POA,
  DOCUMENTS_POI,
  DOCUMENTS_POO,
  DOCUMENTS_POP,
  ENTITY_TYPE,
  FORM_STATUS,
  FORM_TYPE,
  LIMIT_DEFAULT,
  MONTHS,
  REQUEST_STATUS,
  SELL_LIMITS,
  SELL_LIMITS_COMPANY,
  SOF_OPTIONS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  TYPE_COMPANY,
  TYPE_INDIVIDUAL,
  VIP_OPTIONS_COMPANY,
  VIP_OPTIONS_INDIVIDUAL,
  XR_BUY,
  XR_SELL,
}
