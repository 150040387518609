import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import FooterImmersive from "./footer-immersive"
import Header from "./header"
import Section from "./section"
import SideNav from "./tabs/side-nav"

export default function LayoutImmersive(props) {
  const {
    children,
    page,
    bitcoinPrice,
    activeTab,
    setActiveTab,
    userLevel,
    recordOne,
    recordTwo,
    recordThree,
    recordFour,
    recordFive,
    recent,
    user,
  } = props

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          slogan
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title || data.site.siteMetadata?.slogan

  return (
    <>
      <Header siteTitle={title} bitcoinPrice={bitcoinPrice} user={user} />

      <main>
        <Section
          classNameMain="w-screen lg:pt-0 md:pt-0 pt-0 lg:pb-0 md:pb-0 pb-0 flex justify-center bg-white"
          className="w-theme-w min-h-screen flex flex-row"
        >
          <div className="lg:w-56 md:w-52 w-fit">
            <SideNav
              page={page}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              userLevel={userLevel}
              recordOne={recordOne}
              recordTwo={recordTwo}
              recordThree={recordThree}
              recordFour={recordFour}
              recordFive={recordFive}
              recent={recent}
            />
          </div>

          {children}
        </Section>
      </main>

      <FooterImmersive siteTitle={title} />
    </>
  )
}
