import { Link } from "gatsby"
import * as React from "react"

const linkSizeClass = "lg:text-sm md:text-4xl sm:text-2xl text-2xl"

function isActive({ isCurrent }) {
  return isCurrent
    ? {
        className: `menu-link py-2.5 flex justify-center font-bold text-primary-dark hover:text-primary-dark ${linkSizeClass} rounded-none border-solid border-0 border-b-2 border-primary-dark`,
      }
    : {
        className: `menu-link py-2.5 flex justify-center font-bold text-primary-default hover:text-primary-dark ${linkSizeClass} rounded-none border-solid border-0 border-b-2 border-transparent`,
      }
}

const MenuLinks = ({ onClickFn = () => {} }) => {
  return (
    <>
      <li className="lg:w-fit w-full m-0 p-0 lg:mx-4 md:mx-3 sm:mx-2 mx-2 lg:mb-0 md:mb-12 sm:mb-8 mb-8 flex justify-center items-center">
        <Link to="/services" getProps={isActive} onClick={onClickFn}>
          Services
        </Link>
      </li>

      <li className="lg:w-fit w-full m-0 p-0 lg:mx-4 md:mx-3 sm:mx-2 mx-2 lg:mb-0 md:mb-12 sm:mb-8 mb-8 flex justify-center items-center">
        <Link to="/contact-us" getProps={isActive} onClick={onClickFn}>
          Contact Us
        </Link>
      </li>

      <li className="lg:w-fit w-full m-0 p-0 lg:mx-4 md:mx-3 sm:mx-2 mx-2 flex justify-center items-center">
        <Link to="/faq" getProps={isActive} onClick={onClickFn}>
          FAQ
        </Link>
      </li>
    </>
  )
}

export default MenuLinks
