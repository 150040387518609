import { useLocation } from "@reach/router"
import { get } from "aws-amplify/api"
import { DataStore, Predicates, SortDirection } from "aws-amplify/datastore"
import React, { useCallback, useEffect, useState } from "react"
import CustomAuthenticator from "../components/authenticator"
import LayoutImmersive from "../components/layout-immersive"
import {
  AppointmentData,
  BusinessData,
  CompanyData,
  ContactCAData,
  ContactIAData,
  EmploymentData,
  FundData,
  IndividualData,
  UserLevel,
  VIPFundData,
} from "../models"
import { REQUEST_STATUS, TYPE_INDIVIDUAL } from "../utils/constants"
import { onGetBitcoinPrice } from "../utils/kraken"
import { onCheckUser, onDataStoreSync, onScrollTop } from "../utils/utils"

const PageWrapper = ({ children, ...props }) => {
  const location = useLocation()

  const [page, setPage] = useState("dashboard")
  const [bitcoinPrice, setBitcoinPrice] = useState(0)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSynced, setIsSynced] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [activeTab, setActiveTab] = useState("tab-tier-1")
  const [declaration, setDeclaration] = useState("yes")

  const [userLevel, setUserLevel] = useState([])
  const [recordOne, setRecordOne] = useState([])
  const [recordTwo, setRecordTwo] = useState([])
  const [recordThree, setRecordThree] = useState([])
  const [recordFour, setRecordFour] = useState([])
  const [recordFive, setRecordFive] = useState([])

  const [appointmentsData, setAppointmentsData] = useState([])
  const [requestsData, setRequestsData] = useState([])
  const [requestData, setRequestData] = useState([])
  const [transactionsData, setTransactionsData] = useState([])
  const [transactionData, setTransactionData] = useState([])
  const [recent, setRecent] = useState("")

  const handleRecord = (option, record) => {
    setRecent(option)
    switch (option) {
      case "1":
        setRecordOne(record)
        onScrollTop()
        break
      case "2":
        setRecordTwo(record)
        onScrollTop()
        break
      case "3":
        setRecordThree(record)
        onScrollTop()
        break
      case "4":
        setRecordFour(record)
        onScrollTop()
        break
      case "5":
        setRecordFive(record)
        onScrollTop()
        break
      default:
        break
    }
  }

  const listAppointmentsData = useCallback(async () => {
    try {
      setLoading(true)
      setAppointmentsData([])
      const response = await DataStore.query(AppointmentData, Predicates.ALL, {
        sort: a => a.createdAt(SortDirection.DESCENDING),
      })
      if (response !== undefined) {
        const processData = async data => {
          const promises = data.map(async item => {
            return item
          })
          const appointmentData = await Promise.all(promises)
          setAppointmentsData(appointmentData || [])
        }
        await processData(response)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  const listRequestsData = useCallback(
    async filter => {
      try {
        setLoading(true)
        setRequestsData([])
        if (Array.isArray(filter) && filter.length === 0) {
          setLoading(false)
          return
        }

        const restOperation = get({
          apiName: "requestApi",
          path: "/requests",
        })
        const { body } = await restOperation?.response
        const resp = await body?.json()
        const response = resp?.items

        response !== undefined && setRequestsData(response || [])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [setLoading],
  )

  const listTransactionsData = useCallback(
    async filter => {
      try {
        setLoading(true)
        setTransactionsData([])
        if (Array.isArray(filter) && filter.length === 0) {
          setLoading(false)
          return
        }

        const restOperation = get({
          apiName: "requestApi",
          path: "/requests",
          options: {
            queryParams: {
              status: /* complete */ REQUEST_STATUS[7].label,
            },
          },
        })
        const { body } = await restOperation.response
        const resp = await body.json()
        const response = resp?.items

        if (response !== undefined) {
          const processData = async data => {
            const promises = data.map(async item => {
              return item
            })
            const transactionData = await Promise.all(promises)
            setTransactionsData(transactionData || [])
          }
          await processData(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [setLoading],
  )

  useEffect(() => {
    setPage(location.pathname.split("/").filter(Boolean)[1])
  }, [location])

  useEffect(() => {
    onGetBitcoinPrice(setBitcoinPrice)
  }, [])

  useEffect(() => {
    if (!isSynced && !isSyncing)
      onDataStoreSync(setIsSynced, setLoading, setIsSyncing)
  }, [isSynced, isSyncing])

  useEffect(() => {
    const startDataStore = async () => {
      await DataStore.clear()
      await DataStore.start()
    }

    if (!user) {
      onCheckUser(setUser, setLoading)
    } else {
      startDataStore()
    }
  }, [user])

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        setLoading(true)
        const type = user["custom:account"]

        const response = await DataStore.query(UserLevel)
        if (response[0] !== undefined) setUserLevel(response[0])

        const response1 = await DataStore.query(
          type === TYPE_INDIVIDUAL ? IndividualData : CompanyData,
        )
        if (response1[0] !== undefined) setRecordOne(response1[0])

        const response2 = await DataStore.query(
          type === TYPE_INDIVIDUAL ? ContactIAData : ContactCAData,
        )
        if (response2[0] !== undefined) setRecordTwo(response2[0])

        const response3 = await DataStore.query(
          type === TYPE_INDIVIDUAL ? EmploymentData : BusinessData,
        )
        if (response3[0] !== undefined) setRecordThree(response3[0])

        const response4 = await DataStore.query(FundData)
        if (response4[0] !== undefined) setRecordFour(response4[0])

        const response5 = await DataStore.query(VIPFundData)
        if (response5[0] !== undefined) setRecordFive(response5[0])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    if (user && isSynced) getCustomerData()
  }, [user, isSynced])

  useEffect(() => {
    const getRequestData = async () => {
      try {
        setLoading(true)
        setRequestData([])
        const id = location.pathname.split("/").filter(Boolean).pop()

        const restOperation = get({
          apiName: "requestApi",
          path: `/requests/${id}`,
        })
        const { body } = await restOperation?.response
        const resp = await body?.json()
        const response = resp?.item

        response !== undefined && setRequestData(response || [])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const getTransactionData = async () => {
      try {
        setLoading(true)
        setTransactionData([])
        const id = location.pathname.split("/").filter(Boolean).pop()

        const restOperation = get({
          apiName: "requestApi",
          path: `/requests/${id}`,
          options: {
            queryParams: {
              status: /* complete */ REQUEST_STATUS[7].label,
            },
          },
        })
        const { body } = await restOperation.response
        const resp = await body.json()
        const response = resp?.item

        if (
          response !== undefined &&
          response?.status === /* complete */ REQUEST_STATUS[7].label
        )
          setTransactionData(response)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    if (user && isSynced) {
      switch (page) {
        case "appointments":
          listAppointmentsData()
          break
        case "request":
          getRequestData()
          break
        case "requests":
          listRequestsData("")
          break
        case "transaction":
          getTransactionData()
          break
        case "transactions":
          listTransactionsData("")
          break
        default:
          break
      }
    }
  }, [
    user,
    isSynced,
    page,
    location,
    listAppointmentsData,
    listRequestsData,
    listTransactionsData,
  ])

  const getAppointmentsData = () => {
    if (user && isSynced) listAppointmentsData()
  }

  const getRequestsData = () => {
    if (user && isSynced) listRequestsData("")
  }

  const getTransactionsData = () => {
    if (user && isSynced) listTransactionsData("")
  }

  return (
    <CustomAuthenticator initialState={page} loginMechanisms={["email"]}>
      <LayoutImmersive
        page={page}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        bitcoinPrice={bitcoinPrice}
        user={user}
        loading={loading}
        userLevel={userLevel}
        recordOne={recordOne}
        recordTwo={recordTwo}
        recordThree={recordThree}
        recordFour={recordFour}
        recordFive={recordFive}
        recent={recent}
      >
        {React.cloneElement(children, {
          ...props,
          page,
          bitcoinPrice,
          setBitcoinPrice,
          user,
          setUser,
          loading,
          setLoading,
          isSynced,
          activeTab,
          setActiveTab,
          declaration,
          setDeclaration,
          userLevel,
          setUserLevel,
          recordOne,
          recordTwo,
          recordThree,
          recordFour,
          recordFive,
          appointmentsData,
          getAppointmentsData,
          requestsData,
          getRequestsData,
          requestData,
          transactionsData,
          getTransactionsData,
          transactionData,
          recent,
          handleRecord,
        })}
      </LayoutImmersive>
    </CustomAuthenticator>
  )
}

export default PageWrapper
