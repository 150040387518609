const onGetBitcoinPrice = setBitcoinPrice => {
  // Function retrieves the live bitcoin price via Kraken.
  // console.log("Kraken: Get Bitcoin Price")
  try {
    fetch(`https://api.kraken.com/0/public/Ticker?pair=btc/usd`)
      .then(response => response.json())
      .then(data => {
        let rounded = parseFloat(data?.result["BTC/USD"]?.p[0])
        rounded = rounded ? rounded : 0

        setBitcoinPrice(rounded === 0 ? 0 : rounded)
      })
  } catch (e) {
    // console.log(e)
    setBitcoinPrice(0)
  }
}

export { onGetBitcoinPrice }
