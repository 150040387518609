import { Flex, Loader, ThemeProvider } from "@aws-amplify/ui-react"
import React from "react"

const theme = {
  name: "loader-theme",
  tokens: {
    components: {
      loader: {
        strokeEmpty: { value: "{colors.neutral.20}" },
        strokeFilled: { value: "#FF9015" },
        // sizes
        large: {
          width: { value: "{fontSizes.xxl}" },
          height: { value: "{fontSizes.xxl}" },
        },
      },
    },
  },
}

export const LoaderTheme = () => (
  <ThemeProvider theme={theme} colorMode="light">
    <Flex direction="column">
      <Loader size="large" />
    </Flex>
  </ThemeProvider>
)
