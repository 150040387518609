export const schema = {
  models: {
    IndividualData: {
      name: "IndividualData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        firstname: {
          name: "firstname",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastname: {
          name: "lastname",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        nationality: {
          name: "nationality",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        country: {
          name: "country",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        residence: {
          name: "residence",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        poiType: {
          name: "poiType",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "IndividualData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "individualByOwner",
            fields: ["owner"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ContactIAData: {
      name: "ContactIAData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        address1: {
          name: "address1",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        address2: {
          name: "address2",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        city: {
          name: "city",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        parish: {
          name: "parish",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        postalcode: {
          name: "postalcode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        country: {
          name: "country",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        homephone: {
          name: "homephone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workphone: {
          name: "workphone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        cellphone: {
          name: "cellphone",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        poaType: {
          name: "poaType",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ContactIAData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    EmploymentData: {
      name: "EmploymentData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        occupation: {
          name: "occupation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        employer: {
          name: "employer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        address1: {
          name: "address1",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        address2: {
          name: "address2",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        city: {
          name: "city",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        parish: {
          name: "parish",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        postalcode: {
          name: "postalcode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        country: {
          name: "country",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        otitle: {
          name: "otitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        oname: {
          name: "oname",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        orelationship: {
          name: "orelationship",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        convicted: {
          name: "convicted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "EmploymentData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CompanyData: {
      name: "CompanyData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        entitytype: {
          name: "entitytype",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        businesstype: {
          name: "businesstype",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        entityname: {
          name: "entityname",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        companyname: {
          name: "companyname",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        tradingname: {
          name: "tradingname",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        businesstypeother: {
          name: "businesstypeother",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        entitynature: {
          name: "entitynature",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        businessnature: {
          name: "businessnature",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        ubos: {
          name: "ubos",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        shareholders: {
          name: "shareholders",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        directors: {
          name: "directors",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        officers: {
          name: "officers",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        owners: {
          name: "owners",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        individuals: {
          name: "individuals",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        idcard: {
          name: "idcard",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        passport: {
          name: "passport",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        dlicense: {
          name: "dlicense",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        statement: {
          name: "statement",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        letter: {
          name: "letter",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        certificate: {
          name: "certificate",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        system: {
          name: "system",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        partnership: {
          name: "partnership",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CompanyData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "companyByOwner",
            fields: ["owner"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ContactCAData: {
      name: "ContactCAData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        address1: {
          name: "address1",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        address2: {
          name: "address2",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        city: {
          name: "city",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        parish: {
          name: "parish",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        postalcode: {
          name: "postalcode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        country: {
          name: "country",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        mailequal: {
          name: "mailequal",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        mailaddress1: {
          name: "mailaddress1",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mailaddress2: {
          name: "mailaddress2",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mailcity: {
          name: "mailcity",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mailparish: {
          name: "mailparish",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mailpostalcode: {
          name: "mailpostalcode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mailcountry: {
          name: "mailcountry",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        telephone: {
          name: "telephone",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        faxphone: {
          name: "faxphone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        owners: {
          name: "owners",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        shareholders: {
          name: "shareholders",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        utilitybill: {
          name: "utilitybill",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        bankstatement: {
          name: "bankstatement",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        poaother: {
          name: "poaother",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        poa: {
          name: "poa",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ContactCAData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    BusinessData: {
      name: "BusinessData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        certificateincorporation: {
          name: "certificateincorporation",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        certificateregistration: {
          name: "certificateregistration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        bylaws: {
          name: "bylaws",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        otitles: {
          name: "otitles",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        onames: {
          name: "onames",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        orelationships: {
          name: "orelationships",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        convicted: {
          name: "convicted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "BusinessData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    FundData: {
      name: "FundData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        salary: {
          name: "salary",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        savings: {
          name: "savings",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        pension: {
          name: "pension",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        other: {
          name: "other",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        origin: {
          name: "origin",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "FundData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    VIPFundData: {
      name: "VIPFundData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        approve: {
          name: "approve",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        limit: {
          name: "limit",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "VIPFundData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "vipfundByOwner",
            fields: ["owner"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserLevel: {
      name: "UserLevel",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        level: {
          name: "level",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        account: {
          name: "account",
          isArray: false,
          type: {
            enum: "AccountType",
          },
          isRequired: true,
          attributes: [],
        },
        reason: {
          name: "reason",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "UserLevels",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "userlevelByOwner",
            fields: ["owner"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ActivityLog: {
      name: "ActivityLog",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        activity: {
          name: "activity",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ActivityLogs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "activityLogByCreatedAt",
            queryField: "activityLogByCreatedAt",
            fields: ["type", "createdAt"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AppointmentData: {
      name: "AppointmentData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        tier: {
          name: "tier",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        transaction: {
          name: "transaction",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        amount: {
          name: "amount",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        date: {
          name: "date",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        time: {
          name: "time",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        requestId: {
          name: "requestId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        account: {
          name: "account",
          isArray: false,
          type: {
            enum: "AccountType",
          },
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AppointmentData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    RequestData: {
      name: "RequestData",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        currency: {
          name: "currency",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        amount: {
          name: "amount",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        fee: {
          name: "fee",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        btcPrice: {
          name: "btcPrice",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        btc: {
          name: "btc",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        btcAddress: {
          name: "btcAddress",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        addressDesc: {
          name: "addressDesc",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        refid: {
          name: "refid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        rcpt: {
          name: "rcpt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reason: {
          name: "reason",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        expiredAt: {
          name: "expiredAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        appointmentSet: {
          name: "appointmentSet",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        account: {
          name: "account",
          isArray: false,
          type: {
            enum: "AccountType",
          },
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "RequestData",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "requestByOwner",
            fields: ["owner", "createdAt"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ContactForm: {
      name: "ContactForm",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "AWSEmail",
          isRequired: true,
          attributes: [],
        },
        subject: {
          name: "subject",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        message: {
          name: "message",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ContactForms",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create"],
              },
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "read", "update"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    BitcoinAddress: {
      name: "BitcoinAddress",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        address: {
          name: "address",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "BitcoinAddresses",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byAddress",
            queryField: "addressByValue",
            fields: ["address"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Transaction: {
      name: "Transaction",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        value: {
          name: "value",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        fee: {
          name: "fee",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        receiver: {
          name: "receiver",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Transactions",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Admins", "Tellers"],
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    TransactionType: {
      name: "TransactionType",
      values: ["TRANSFER", "DEPOSIT", "WITHDRAWAL"],
    },
    TransactionState: {
      name: "TransactionState",
      values: ["PENDING", "CONFIRMED", "FAILED", "CANCELLED"],
    },
    WalletType: {
      name: "WalletType",
      values: ["HOT", "COLD", "CUSTODY"],
    },
    TransferType: {
      name: "TransferType",
      values: ["SEND", "RECEIVE", "INTERNAL"],
    },
    AccountType: {
      name: "AccountType",
      values: ["individual", "company"],
    },
  },
  nonModels: {},
  codegenVersion: "3.4.4",
  version: "4c71ef7c47992a283df95acc8e46054e",
}
