import { config } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { XR_BUY } from "../utils/constants"
import { onOpenPage } from "../utils/utils"
import MenuButtons from "./header-elements/menu-buttons"
import MenuLinks from "./header-elements/menu-links"
// font awesome: disable the auto css insertion
config.autoAddCss = false

const Header = ({
  onClickFn = () => {},
  user,
  siteTitle,
  bitcoinPrice,
  paddingXClass = "2xl:px-8 xl:px-8 lg:px-8 md:px-6 sm:px-4 px-4",
}) => {
  const [priceUSD, setPriceUSD] = useState("")
  const [priceBBD, setPriceBBD] = useState("")
  const [animateHeader, setAnimateHeader] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenuClose = () => setMenuOpen(false)
  const handleToggle = () => setMenuOpen(!menuOpen)
  const isBrowser = typeof window !== "undefined"
  const handleResize = () => {
    if (menuOpen && window.innerWidth > 1024) handleMenuClose()
  }
  const location = useLocation()
  if (isBrowser) window.addEventListener("resize", handleResize)

  const onOpenDashboard = () => onOpenPage("dashboard")

  useEffect(() => {
    const listener = () => setAnimateHeader(window.scrollY > 100)
    window.addEventListener("scroll", listener)
    return () => window.removeEventListener("scroll", listener)
  }, [location.pathname])

  useEffect(() => {
    bitcoinPrice &&
      setPriceUSD(bitcoinPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    bitcoinPrice &&
      setPriceBBD(
        (bitcoinPrice * XR_BUY)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      )
  }, [bitcoinPrice])

  return (
    <>
      <header
        id="header"
        className={`w-full sticky top-0 z-50 flex justify-center transition-colors duration-1000 ease-in-out ${
          animateHeader
            ? "bg-header-sticky/90 backdrop-blur"
            : "bg-header-default"
        } hover:bg-header-default border-b border-solid border-border-color-light`}
      >
        <div
          id="header-container"
          className={`w-full flex flex-col items-center flex-wrap ${
            priceUSD !== "" ? "h-header" : "h-header-default"
          }`}
        >
          <div
            id="header-top"
            className="w-full h-header-top flex justify-center bg-primary-light border-none"
          >
            <div
              className={`w-full max-w-theme-max-w ${paddingXClass} flex-row justify-center items-center text-secondary-default lg:text-sm md:text-base text-sm ${
                priceUSD !== "" ? "flex" : "hidden"
              }`}
            >
              <div className="flex flex-row items-center">
                <StaticImage
                  src="../images/img-bitcoin-icon.png"
                  loading="eager"
                  height={20}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Bitcoin Icon"
                  placeholder="none"
                  className="lg:flex md:flex hidden"
                />

                <span className="lg:ml-1 md:ml-2 ml-0 font-bold">1 BTC</span>
              </div>

              <div className="lg:mx-4 md:mx-3 mx-2">&rarr;</div>

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../images/img-usa-flag.png"
                  loading="eager"
                  height={20}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="USA Flag"
                  placeholder="none"
                  className="lg:flex md:flex hidden"
                />

                <span className="lg:ml-1 md:ml-2 ml-0 font-bold">
                  USD <span className="font-medium">${priceUSD}</span>
                </span>
              </div>

              <div className="lg:mx-4 md:mx-3 mx-2">&rarr;</div>

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../images/img-barbados-flag.png"
                  loading="eager"
                  height={20}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Barbados Flag"
                  placeholder="none"
                  className="lg:flex md:flex hidden"
                />

                <span className="lg:ml-1 md:ml-2 ml-0 font-bold">
                  BBD <span className="font-medium">${priceBBD}</span>
                </span>
              </div>
            </div>

            <div
              className={`w-full max-w-theme-max-w ${paddingXClass} flex-row justify-center items-center text-secondary-default lg:text-sm md:text-base text-sm ${
                priceUSD === "" ? "flex" : "hidden"
              }`}
            >
              <div className="flex flex-row items-center tracking-wider">
                <span className="mr-1 font-bold">
                  Bitcoin.bb, Buy &amp; Sell
                </span>

                <StaticImage
                  src="../images/img-bitcoin-icon.png"
                  loading="eager"
                  height={20}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Bitcoin Icon"
                  placeholder="none"
                  className=""
                />

                <span className="ml-1 font-bold">Here</span>
              </div>
            </div>
          </div>

          <div
            id="header-botton"
            className={`w-full max-w-theme-max-w h-header-bottom flex justify-between items-center ${paddingXClass}`}
          >
            <Link id="header-logo" to="/">
              <StaticImage
                src="../images/img-logo.png"
                loading="eager"
                height={50}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt={siteTitle}
                placeholder="none"
              />
            </Link>

            <div
              id="header-menu"
              className="w-fit lg:flex hidden flex-col flex-wrap justify-end items-center content-center"
            >
              <ul className="w-fit h-fit m-0 p-0 flex flex-row">
                <MenuLinks onClickFn={handleMenuClose} />
                <MenuButtons onClickFn={handleMenuClose} user={user} />
              </ul>
            </div>

            <div
              id="header-mobile"
              className="lg:hidden flex flex-row justify-center"
            >
              <div
                id="header-mobile-button-b"
                className="lg:hidden flex flex-col justify-center md:mr-6 sm:mr-4 mr-4"
              >
                {user ? (
                  <>
                    <button
                      id="header-mobile-user-profile"
                      aria-label="Customer Dashboard"
                      className="menu-button flex justify-center items-center font-bold"
                      onClick={onOpenDashboard}
                    >
                      <div className="md:flex sm:hidden hidden flex-col justify-center items-center text-primary-default hover:text-primary-dark">
                        {user && user?.email}
                      </div>

                      <div className="w-10 h-10 ml-2 pt-0.5 flex flex-col justify-center items-center text-white rounded-full bg-primary-dark">
                        {user && Array.from(user?.email)[0].toUpperCase()}
                      </div>
                    </button>
                  </>
                ) : (
                  <>
                    <a
                      href="/login"
                      className="menu-button w-24 py-2.5 flex justify-center font-bold text-white bg-primary-default hover:bg-primary-dark lg:text-sm md:text-base sm:text-base text-base rounded-full border-solid border-2 border-primary-default hover:border-primary-dark"
                      onClick={onClickFn}
                    >
                      Log in
                    </a>
                  </>
                )}
              </div>

              <div
                id="header-mobile-button-a"
                className="lg:hidden flex flex-col justify-center"
              >
                <button id="header-mobile-icons" onClick={handleToggle}>
                  {menuOpen ? (
                    <StaticImage
                      src="../images/img-menu-close.png"
                      loading="eager"
                      height={36}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Bitcoin.bb Close Menu"
                      placeholder="none"
                    />
                  ) : (
                    <StaticImage
                      src="../images/img-menu-open.png"
                      loading="eager"
                      height={36}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Bitcoin.bb Open Menu"
                      placeholder="none"
                    />
                  )}
                </button>

                <ul
                  className={`absolute inset-0 z-50 w-screen h-page-default m-0 p-0 mt-page-header bg-white ${
                    menuOpen ? "flex flex-col justify-between" : "hidden"
                  }`}
                >
                  <li className="m-0 p-0 md:m-6 sm:m-4 m-4 md:mt-16 sm:mt-12 mt-12 flex justify-center">
                    <ul className="w-full m-0 p-0 flex flex-col justify-center items-center">
                      <MenuLinks onClickFn={handleMenuClose} />
                    </ul>
                  </li>

                  <li className="m-0 p-0 md:m-6 sm:m-4 m-4 md:mb-16 sm:mb-12 mb-12 flex flex-row justify-evenly">
                    <OutboundLink
                      href="https://www.instagram.com/bitcoin.bb/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-primary-dark"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="md:w-12 md:h-12 w-10 h-10"
                      />
                    </OutboundLink>

                    <OutboundLink
                      href="https://www.facebook.com/bitcoinbarbados/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-primary-dark"
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="md:w-12 md:h-12 w-10 h-10"
                      />
                    </OutboundLink>

                    <OutboundLink
                      href="https://twitter.com/bitcoinbarbados/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-primary-dark"
                    >
                      <FontAwesomeIcon
                        icon={faXTwitter}
                        className="md:w-12 md:h-12 w-10 h-10"
                      />
                    </OutboundLink>

                    <OutboundLink
                      href="https://www.linkedin.com/company/bitcoinbarbados/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-primary-dark"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="md:w-12 md:h-12 w-10 h-10"
                      />
                    </OutboundLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
